import axios from "axios";


export const getCategories = async () =>
  await axios.get(`https://odd-lime-cobra-hat.cyclic.app/api/category/categories`,{
  headers: { 'Content-Type': 'application/json'}})


export const getCategory = async (slug) =>
  await axios.get(`https://odd-lime-cobra-hat.cyclic.app/api/category/category/${slug}`);

export const removeCategory = async (slug, authtoken) =>
  await axios.delete(`https://odd-lime-cobra-hat.cyclic.app/api/category/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const updateCategory = async (slug, category, authtoken) =>
  await axios.put(`https://odd-lime-cobra-hat.cyclic.app/api/category/${slug}`, category, {
    headers: {
      authtoken,
    },
  });

// export const createCategory =()=> async (category,getState) =>{
// try{
//   const {
//     userLogin: { userInfo },
//   } = getState()

//   const config = {
//     headers: {
//       Authorization: `Bearer ${userInfo.token}`,
//     },
//   }
//   await axios.post(`http://localhost:5000/api/category/createcategory`,category, config)
// }
// catch(error)
// {

// }
// }
// export const createCategory = async (category, authtoken) =>
//   await axios.post(`http://localhost:5000/api/category/createcategory`, category, {

//     headers: {
//       A,
//     },
//   });

export const addVariants = (id)=> async (variants) =>
  await axios.post(`https://odd-lime-cobra-hat.cyclic.app/api/products/variants/${id}`, variants, {
    headers: { 'Content-Type': 'application/json'}
  });



// export const getCategorySubs = async (_id) =>
//   await axios.get(`https://odd-lime-cobra-hat.cyclic.app/category/subs/${_id}`);
