import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getCategory, updateCategory } from "../../actions/category";
import CategoryForm from "../../components/CategoryForm";
import axios from "axios";

const CategoryUpdate = ({ history, match }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCategory();
  }, []);

  const loadCategory = () =>
    getCategory(match.params.slug).then((c) => setName(c.data.name));

  const handleSubmit = async(e) => {
   const slug = match.params.slug
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    const userData = {
      name: name
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${userInfo.token}`
    }
    
    //console.log(match.params.slug)
    await axios.put(`https://odd-lime-cobra-hat.cyclic.app/api/category/${slug}`,userData,{headers:headers}).then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        // toast.success(`"${res.data.name}" is updated`);
        history.push("/admin/createcategory");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        // if (err.response.status === 400) toast.error(err.response.data);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
        
        </div>
        <div className="col">
          {loading ? (
            <h4 className="text-danger">Loading..</h4>
          ) : (
            <h4>Update category</h4>
          )}

          <CategoryForm
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
          />

          <hr />
        </div>
      </div>
    </div>
  );
};

export default CategoryUpdate;
