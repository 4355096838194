import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown, NavItem } from 'react-bootstrap'
import SearchBox from './SearchBox'
import { Link } from 'react-router-dom'
import { logout } from '../actions/userActions'
import "../components/Header.css"
import Search from './Search'
// import { useNavigate } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }
  // const loginHandler = () =>{
  //   navigate('/login')
  // }

  return (
    <header>
      <Navbar style={{backgroundColor:"black"}} variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand>MachVapes</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            {/* <Route render={({ history }) => <SearchBox history={history} />} /> */}
            <Nav className='ml-auto'>
            <a style={{color:"white", backgroundColor:"grey", padding:10, margin:2, borderRadius:5}}href="tel:+19496852188">Call Us : +1(949)685-2188</a>
            <NavItem><Route render={({ history }) => <SearchBox  history={history} />} /></NavItem>
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Cart
                  $
              {cartItems
                .reduce((acc, item) => acc + item.qty * item.price, 0)
                .toFixed(2)}
                </Nav.Link>
              {/* search bar icon  */}
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  {userInfo.email !=="guest@test.com"?
                 ( <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>):(<NavDropdown.Item onClick={logoutHandler}>
                    Sign-in
                  </NavDropdown.Item>
                          )}
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/createcategory'>
                    <NavDropdown.Item>Categories</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/variants'>
                    <NavDropdown.Item>Variants</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              
              <NavDropdown title="Categories" id="categoriesmenu">
              <LinkContainer to='/category/disposablevapes'>
                    <NavDropdown.Item>Disposable vapes</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category/delta8' >
                    <NavDropdown.Item>Delta 8</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category/vapejuice' >
                    <NavDropdown.Item>Vape Juice</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category/devices'>
                    <NavDropdown.Item>Devices</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category/accessories'>
                    <NavDropdown.Item>Accessories</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/category/bestsellers'>
                    <NavDropdown.Item>Best Seller</NavDropdown.Item>
                  </LinkContainer>            

              </NavDropdown>
      {/* <option value="63e11b97aa42108420067e0d">Delta 8 </option>
      <option value="63e11b90aa42108420067e0a">Nic Salts</option>
      <option value="63e11b86aa42108420067e07">Vape Juice</option>
      <option value="63e11b70aa42108420067e04">Devices</option>
      <option value="63e11b5faa42108420067e01">Accessories</option>
      <option value="63e11b3daa42108420067dfe">Best Seller</option>  */}
        {/* <a href='http://localhost:3000/category/accessories'>
                    <NavDropdown.Item>Accessories</NavDropdown.Item>
                  </a>
                  <a href='http://localhost:3000/category/bestsellers'>
                    <NavDropdown.Item>Best Seller</NavDropdown.Item>
                  </a>  */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="head" style={{backgroundColor:"black" ,color:"white", textAlign:"center"}}>We Deliver to : Orange | Tustin | Irvine | Lake Forest | Mission Viejo | Laguna Hills | Laguna Niguel | Foothill Ranch | Rancho Santa Margarita | Aliso Viejo</div>
    </header>
  )
}

export default Header
