import React, { useState, useEffect } from "react";
// import AdminNav from "../../../components/nav/AdminNav";
 import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
// import {
//   createCategory,
//   getCategories,
//   removeCategory,
// } from "../../actions/category"
// import { Link } from "react-router-dom";
// import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
// import CategoryForm from "../../components/CategoryForm";
// import LocalSearch from "../../components/LocalSearch";
import { addVariants } from "../actions/category";
import VariantsForm from "../components/VariantsForm";

const AddVariants = () => {
 
//   const userLogin = useSelector((state) => state.userLogin)
//   const { userInfo } = userLogin

  const [name, setName] = useState("");
  const [productID,setProductID]=useState("");
  const [_id,setID]=useState("");
  const [loading, setLoading] = useState(false);
//   const [categories, setCategories] = useState([]);
  // step 1
//   const [keyword, setKeyword] = useState("");

//   useEffect(() => {
//     loadCategories();
//   }, []);

//   const loadCategories = () =>
//     getCategories().then((c) => setCategories(c.data));

  const handleSubmit = async(e) =>  {
    e.preventDefault();
    // console.log(name);
    setLoading(true);
    const userData = {
        _id: _id,
        name: name

      };
      const id = productID
      console.log(userData)
      console.log(id)
      
      await axios.post(`https://odd-lime-cobra-hat.cyclic.app/api/products/variants/${id}`, userData).then((res) => {
        // console.log(res)
        setLoading(false);
        setName("");
        setID("");
        setProductID("");
        toast.success(`${res} variant is added`);
        // loadCategories();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 400) toast.error(err.response.data);
      });
  };

//   const handleRemove = async (slug) => {
//     // let answer = window.confirm("Delete?");
//     // console.log(answer, slug);
//     if (window.confirm("Delete?")) {
//       setLoading(true);
//       removeCategory(slug, userInfo.token)
//         .then((res) => {
//           setLoading(false);
//           toast.error(`${res.data.name} deleted`);
//           loadCategories();
//         })
//         .catch((err) => {
//           if (err.response.status === 400) {
//             setLoading(false);
//             toast.error(err.response.data);
//           }
//         });
//     }
//   };

  // step 4
//   const searched = (keyword) => (c) => c.name.toLowerCase().includes(keyword);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
      
        </div>
        <div className="col">
          {loading ? (
            <h4 className="text-danger">Loading..</h4>
          ) : (
            <h4>Add Variants</h4>
          )}

          <VariantsForm
            handleSubmit={handleSubmit}
            name={name}
            setName={setName}
            productID={productID}
            setProductID={setProductID}
            _id={_id}
            setID={setID}
          />

          {/* step 2 and step 3 */}
          {/* <LocalSearch keyword={keyword} setKeyword={setKeyword} /> */}

          {/* step 5 */}
          {/* {categories.filter(searched(keyword)).map((c) => (
            <div className="alert alert-secondary" key={c._id}>
              {c.name}
              <span
                onClick={() => handleRemove(c.slug)}
                className="btn btn-sm float-right"
              >
                <DeleteOutlined className="text-danger" />
              </span>
              <Link to={`/admin/updateCategory/${c.slug}`}>
                <span className="btn btn-sm float-right">
                  <EditOutlined className="text-warning" />
                </span>
              </Link>
            </div>
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default AddVariants;
