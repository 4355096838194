import React from "react"

const PrivacyScreen =()=>{

    return(
        
      <div>
<h1>Welcome to our Privacy Policy</h1>
<h3>Your privacy is critically important to us.</h3>
<p>OcBudz is located at:</p>

<p>OcBudz
2900 Bristol Street, Building E, Suite 208, Costa Mesa
92626– CA , United States
(949) 441-8195</p>
<p>
It is OcBudz‘s policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to OcBudz (hereinafter, “us”, “we”, or “OcBudz“). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy (“Privacy Policy”) to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.

This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.

Website Visitors
Like most website operators, OcBudz collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. OcBudz‘s purpose in collecting non-personally identifying information is to better understand how OcBudz‘s visitors use its website. From time to time, OcBudz may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.

OcBudz also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on http://OcBudz blog posts. OcBudz only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.

Gathering of Personally-Identifying Information
Certain visitors to OcBudz‘s websites choose to interact with OcBudz in ways that require OcBudz to gather personally-identifying information. The amount and type of information that OcBudz gathers depends on the nature of the interaction. For example, we ask visitors who sign up for a blog at OcBudz to provide a username and email address.

<p>Security
The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

Advertisements
Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by OcBudz and does not cover the use of cookies by any advertisers.

Links To External Sites
Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.

We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.

OcBudz uses Google AdWords for remarketing
OcBudz uses the remarketing services to advertise on third party websites (including Google) to previous visitors to our site. It could mean that we advertise to previous visitors who haven’t completed a task on our site, for example using the contact form to make an enquiry. This could be in the form of an advertisement on the Google search results page, or a site in the Google Display Network. Third-party vendors, including Google, use cookies to serve ads based on someone’s past visits. Of course, any data collected will be used in accordance with our own privacy policy and Google’s privacy policy.

You can set preferences for how Google advertises to you using the Google Ad Preferences page, and if you want to you can opt out of interest-based advertising entirely by cookie settings or permanently using a browser plugin.

Protection of Certain Personally-Identifying Information
OcBudz discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on OcBudz‘s behalf or to provide services available at OcBudz‘s website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using OcBudz‘s website, you consent to the transfer of such information to them. OcBudz will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, OcBudz discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when OcBudz believes in good faith that disclosure is reasonably necessary to protect the property or rights of OcBudz, third parties or the public at large.

If you are a registered user of http://OcBudz and have supplied your email address, OcBudz may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with OcBudz and our products. We primarily use our blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. OcBudz takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.

Aggregated Statistics
OcBudz may collect statistics about the behavior of visitors to its website. OcBudz may display this information publicly or provide it to others. However, OcBudz does not disclose your personally-identifying information.

Cookies
To enrich and perfect your online experience, OcBudz uses “Cookies”, similar technologies and services provided by others to display personalized content, appropriate advertising and store your preferences on your computer.

A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. OcBudz uses cookies to help OcBudz identify and track visitors, their usage of http://OcBudz, and their website access preferences. OcBudz visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using OcBudz‘s websites, with the drawback that certain features of OcBudz‘s websites may not function properly without the aid of cookies.

By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to OcBudz‘s use of cookies.

E-commerce
Those who engage in transactions with OcBudz – by purchasing OcBudz‘s services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, OcBudz collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction with OcBudz. OcBudz does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.

Privacy Policy OcBudz
Although most OcBudz are likely to be minor, OcBudz may change its Privacy Policy from time to time, and in OcBudz‘s sole discretion. OcBudz encourages visitors to frequently check this page for any OcBudz to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
      </div>

    )

}

export default PrivacyScreen