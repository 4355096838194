import React from "react";

const VariantsForm = ({ handleSubmit, name, setName , productID,setProductID, _id, setID}) => (
  <form onSubmit={handleSubmit}>
    <div className="form-group">
      <label>Main Product</label>
      <input
        type="text"
        className="form-control"
        onChange={(e) => setProductID(e.target.value)}
        value={productID}
        autoFocus
        required
      />

      <label>Product ID</label>
      <input
        type="text"
        className="form-control"
        onChange={(e) => setID(e.target.value)}
        value={_id}
        autoFocus
        required
      />
      <label>Name</label>
      <input
        type="text"
        className="form-control"
        onChange={(e) => setName(e.target.value)}
        value={name}
        autoFocus
        required
      />
      <br />
      <button className="btn btn-outline-primary">Save</button>
    </div>
  </form>
);

export default VariantsForm;
