import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Paginate from '../components/Paginate'
import ProductCarousel from '../components/ProductCarousel'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
import SearchBox from '../components/SearchBox'
import { Route } from 'react-router-dom'
import classes from "./categories.css"
import HorizontalScroll from 'react-horizontal-scrolling'
import CategoryList from "../screens/category/CategoryList"
import { ReactSmartScroller } from '@emmyclothing/react-smart-scroller'
import $ from 'jquery'; 
import jQuery from 'jquery'
import "./popup.css"
import Footer from '../components/Footer'


const HomeScreen = ({ match }) => {
  
$(function() {

  //Check it the user has been accpeted the agreement
  if (!localStorage.getItem('accepted')) {
    $("#popup").show();
  }

  $('[data-popup-close]').on('click', function(e) {
    var targeted_popup_class = jQuery(this).attr('data-popup-close');
    $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);

    //Set a cookie to remember the state
    localStorage.setItem('accepted', true);

    e.preventDefault();
  });
});
  // $(function() {

  //   //Check it the user has been accpeted the agreement
  //   if (!(document.cookie && document.cookie == "accepted")) {
  //     $("#popup").show();
  //   }
  
  //   $('[data-popup-close]').on('click', function(e) {
  //     var targeted_popup_class = jQuery(this).attr('data-popup-close');
  //     $('[data-popup="' + targeted_popup_class + '"]').fadeOut(350);
  
  //     //Set a cookie to remember the state
  //     document.cookie = "accepted";
  
  //     e.preventDefault();
  //   });
  
  // });
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber))
  }, [dispatch, keyword, pageNumber])

  return (
    <>
    <div id="popup" data-popup="popup-1">
    <div class="verify-window">
        <h1>MachVapes</h1>
        {/* <p>Are you at least 21 years old?</p> */}
<h3>
    Are you 21+ ?</h3>
        <div class="button-yes" data-popup-close="popup-1">
            Yes
        </div>

        <a href="https://www.google.com" target="_parent">
            <div class="button-no">
                No
            </div>
        </a>
        <p>By entering the website you are agreeing to the Terms of Use and <Link to="/privacy" style={{color:"black"}}>Privacy Policy</Link>.</p>
    </div>
</div>
      <Meta />
      <h2>Latest products</h2>
      {!keyword ? (
        
        <ProductCarousel />
      ) : (
        <Link to='/' className='btn btn-light'>
          Go Back
        </Link>
      )}
      {/* <h1>Latest Products</h1> */}
   
      <h1>Products</h1>
      <h3>Categories</h3>
     
     <ReactSmartScroller   trackProps={{backgroundColor:"black"}}>
      {/* thumb={ <div style={{width: 20,
     height: 10,backgroundColor: 'black'}}/>} 
     
      <button  className= "categorybtn"><Link to={`products/categories/Nic-Disposables`}>Disposable Pods</Link></button>
      <button  className= "categorybtn">Nic Disposables</button>
      <button  className= "categorybtn">Pods</button>
      <button  className= "categorybtn">Edibles</button>
      <button  className= "categorybtn">Cartridge</button>
      <button  className= "categorybtn">Flower</button>
      <button  className= "categorybtn">Bud</button>  */}
      <CategoryList/>
      </ReactSmartScroller>
      <div style={{marginTop:20}}> 
      {/* <Route render={({ history }) => <SearchBox  history={history} />} /> */}
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Row>
            {products.map((product) => (
              <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                <Product product={product} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={pages}
            page={page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
      <Footer/>
    </>
    
  )
}

export default HomeScreen
